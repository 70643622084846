import { Video } from '@/features/console/animator/components/video/index';
import { type Animation, AnimationStatus } from '@/state/query/animator';
import { useOrganization } from '@clerk/nextjs';
import {
	Avatar,
	CallToAction,
	extractFirstName,
	Flex,
	Img,
	Text,
} from '@nex/labs';
import { useCallback } from 'react';
import styles from './animation-card.module.scss';

type AnimationCardProps = {
	animation: Animation;
};

export const AnimationCard = ({ animation }: AnimationCardProps) => {
	const { organization } = useOrganization();

	const is = useCallback(
		(status: AnimationStatus) => animation.status === status,
		[animation.status]
	);

	return (
		<Flex.Column gap={8} className={styles.AnimationCard}>
			<div>
				<RenderIf condition={is(AnimationStatus.COMPLETED)}>
					<Video animation={animation} slug={organization?.slug} />
				</RenderIf>

				<RenderIf condition={is(AnimationStatus.PROCESSING)}>
					<Img
						src="/images/empty_image.png"
						alt="animation"
						key={animation.id}
						className="h-[200px] basis-[100%] w-full rounded-md object-cover object-center border-[1px] border-[var(--primary-gray)] border-solid"
					/>
				</RenderIf>

				{animation?.description && (
					<div className={styles.Generations__Meta}>
						<Text weight={600} noOfLines={2}>
							{animation.description}
						</Text>
					</div>
				)}
			</div>
			<Flex
				gap={8}
				className={styles.Generations__Meta}
				fullWidth
				justifyContent="space-between">
				<Flex gap={4} alignItems="center">
					<Avatar
						src={animation?.user.photo}
						alt={extractFirstName(animation?.user.name)}
						size={24}
					/>

					<Text weight={600}>{extractFirstName(animation?.user.name)}</Text>
				</Flex>
				<CallToAction.a
					size="xs"
					className="h-fit ml-auto"
					variant="secondary"
					href={`/animator/${animation.id}`}>
					View
				</CallToAction.a>
			</Flex>
		</Flex.Column>
	);
};

type RenderIfProps = {
	condition: boolean;
	children: React.ReactNode;
};

const RenderIf = (props: RenderIfProps) => {
	return props.condition ? <>{props.children}</> : null;
};
